import React from 'react'

import '../../../styles/About/Route/Experience.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons'

const Experience = () => {
    return (
        <>
            <div className="experience">
                <div className="experience__container">
                    <div className="experience__header">
                        <h2 className="experience__number">0<span>2</span></h2>
                        <h1 data-aos='fade-down' data-aos-offset="0">E<span>ducation</span>.</h1>
                    </div>
                    <div className="experience__description">
                        <div className="experience__main-box">
                            <div className="experience__box">
                                <h2>General Assembly</h2>
                                <h3><FontAwesomeIcon icon={faCalendarDays} className="experience__calendar" />&nbsp; &nbsp;10/31/2022 - 02/06/2023</h3>
                                <ul>
                                    <p>What happens at bootcamp -</p>
                                    <li data-aos='fade-right' data-aos-delay='200'>
                                        <span>Fundamentals of web development: I learned the basics of web development, including HTML, CSS, and JavaScript.</span>
                                    </li>
                                    <li data-aos='fade-right' data-aos-delay='400'>
                                        <span>Front-end frameworks: I learned about popular front-end frameworks such as React and Angular, and how to use them to build interactive and dynamic web applications.</span>
                                    </li>
                                    <li data-aos='fade-right' data-aos-delay='600'>
                                        <span>Back-end development: I learned how to build the server-side of a web application, including database management and server-side scripting.</span>
                                    </li>
                                    <li data-aos='fade-right' data-aos-delay='800'>
                                        <span>Full-stack development: I learned how to connect the front-end and back-end of a web application, and how to deploy and manage a full-stack application in a production environment.</span>
                                    </li>
                                </ul>
                            </div>

                            {/* You can put this repeated code as a child component similar to internship.js  */}
                            <div className="experience__box">
                                <h2>Northern Illinois University</h2>
                                <h3><FontAwesomeIcon icon={faCalendarDays} className="experience__calendar" />&nbsp; &nbsp;08/22/2019 - 05/20/2021</h3>
                                <ul>
                                    <p>Marketing Major -</p>
                                    <li data-aos='fade-right' data-aos-delay='200' data-aos-offset="-5000">
                                        <span>Principles of Marketing: This course covers the fundamental concepts of marketing such as market segmentation, target marketing, and positioning.</span>
                                    </li>
                                    <li data-aos='fade-right' data-aos-delay='400' data-aos-offset="-5000">
                                        <span>Consumer Behavior: This course focuses on how consumers make purchasing decisions and how marketers can influence those decisions.</span>
                                    </li>
                                    <li data-aos='fade-right' data-aos-delay='600' data-aos-offset="-5000">
                                        <span>Marketing Research: This course teaches students how to design and conduct research studies to gather information about customers and markets.</span>
                                    </li>
                                    <li data-aos='fade-right' data-aos-delay='800' data-aos-offset="-5000">
                                        <span>Marketing Communications: This course covers advertising, public relations, sales promotion, and other marketing communications tools.</span>
                                    </li>
                                    <li data-aos='fade-right' data-aos-delay='1000' data-aos-offset="-5000">
                                        <span>Sales Management: This course focuses on the management and coordination of the sales function, including the development of sales strategies and sales force management.</span>
                                    </li>
                                    <li data-aos='fade-right' data-aos-delay='1200' data-aos-offset="-5000">
                                        <span>Product Management: This course covers the development, pricing, promotion, and distribution of products.</span>
                                    </li>
                                    <li data-aos='fade-right' data-aos-delay='1400' data-aos-offset="-5000">
                                        <span>Digital Marketing: This course teaches students how to effectively use digital channels, such as social media and websites, to reach customers and promote products.</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="experience__box">
                                <h2>Waubonsee Community College</h2>
                                <h3><FontAwesomeIcon icon={faCalendarDays} className="experience__calendar" />&nbsp; &nbsp;08/20/2015 - 05/20/2019</h3>
                                <ul>
                                    <p>Associate of Arts -</p>
                                    <li data-aos='fade-right' data-aos-delay='200' data-aos-offset="-5000">
                                        <span>Principles of Marketing: This course covers the fundamental concepts of marketing such as market segmentation, target marketing, and positioning.</span>
                                    </li>
                                    <li data-aos='fade-right' data-aos-delay='400' data-aos-offset="-5000">
                                        <span>Consumer Behavior: This course focuses on how consumers make purchasing decisions and how marketers can influence those decisions.</span>
                                    </li>
                                    <li data-aos='fade-right' data-aos-delay='600' data-aos-offset="-5000">
                                        <span>Marketing Research: This course teaches students how to design and conduct research studies to gather information about customers and markets.</span>
                                    </li>
                                    <li data-aos='fade-right' data-aos-delay='800' data-aos-offset="-5000">
                                        <span>Marketing Communications: This course covers advertising, public relations, sales promotion, and other marketing communications tools.</span>
                                    </li>
                                    <li data-aos='fade-right' data-aos-delay='1000' data-aos-offset="-5000">
                                        <span>Sales Management: This course focuses on the management and coordination of the sales function, including the development of sales strategies and sales force management.</span>
                                    </li>
                                    <li data-aos='fade-right' data-aos-delay='1200' data-aos-offset="-5000">
                                        <span>Product Management: This course covers the development, pricing, promotion, and distribution of products.</span>
                                    </li>
                                    <li data-aos='fade-right' data-aos-delay='1400' data-aos-offset="-5000">
                                        <span>Digital Marketing: This course teaches students how to effectively use digital channels, such as social media and websites, to reach customers and promote products.</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Experience
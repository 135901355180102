import React from 'react'


import '../../../styles/About/Route/Graduation.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons'

const Graduation = () => {
    return (
        <>
            <div className="graduation">
                <div className="graduation__container">
                    <div className="graduation__header">
                        <h2 className="graduation__number">0<span>4</span></h2>
                        <h1 data-aos='fade-down' data-aos-offset="0">H<span>obbies</span>.</h1>
                    </div>

                    <div className="graduation__description">
                        <h1>Hobbies</h1>
                        <h2><FontAwesomeIcon icon={faCalendarDays} className="internships__calendar" />&nbsp;&nbsp;08/07/1997 - PRESENT</h2>
                        <p>In addition to my passion for web development and design, I also have a love for gaming and extreme sports. When I'm not working on websites, you can often find me immersed in the latest video games or building my own gaming PC. I enjoy the challenge of optimizing my setup for the best performance and enjoy trying out new games and hardware.
                            <br />
                            <br />
                            I am also an avid skateboarder and snowboarder. There's something freeing about being out on a board, whether it's cruising down the street on my skateboard or carving down a mountain on my snowboard. I love the thrill of it and the feeling of being one with my board. These activities allow me to clear my mind and just enjoy the moment.
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Graduation
import React from "react";
import { Link } from "react-router-dom";

import "../../styles/About/About.scss";

const About = () => {

  return (
    <>
      <div className="about" id="about">
        <div className="about__container">
          <div className="about__header" id="about__heading">
            <h1 data-aos="fade-down" data-aos-delay="0" data-aos-offset="20">
              <span
                data-aos="fade-down"
                data-aos-delay="0"
                data-aos-offset="200"
              >
                A
              </span>
              <span
                data-aos="fade-down"
                data-aos-delay="200"
                data-aos-offset="200"
              >
                B
              </span>
              <span
                data-aos="fade-down"
                data-aos-delay="400"
                data-aos-offset="200"
              >
                O
              </span>
              <span
                data-aos="fade-down"
                data-aos-delay="600"
                data-aos-offset="200"
              >
                U
              </span>
              <span
                data-aos="fade-down"
                data-aos-delay="800"
                data-aos-offset="200"
              >
                T
              </span>
              <span
                data-aos="fade-down"
                data-aos-delay="1000"
                data-aos-offset="200"
              >
                .
              </span>
            </h1>
            <p></p>
          </div>

          <div className="about__details-container">
            <div
              className="about__details"
              data-aos="fade-down"
              data-aos-offset="0"
              data-aos-delay="0"
              data-aos-once="true"
            >
              <Link to="/about/intro">
                <h1>
                  0<span>1</span>
                </h1>
                <h2>
                  H<span>ello,</span>
                </h2>
                <br />
                <p>
                  I am <span>Brandon Tobin.</span> <br /> Hi there! My name is Brandon Tobin and I am a new software developer with 6 months of experience under my belt. I am passionate about coding and love to constantly challenge myself to learn and grow as a developer. I am eager to work with others who share my passion for technology and am always open to new ideas and approaches. I am a quick learner and am dedicated to improving my skills and knowledge on a daily basis. I am confident in my abilities and am always striving to do my best.

                  If you're looking for a new developer who is dedicated to growth and learning, then I'm your guy. I would love the opportunity to work with you and help bring your ideas to life.
                </p>
              </Link>
            </div>
            <div
              className="about__details"
              data-aos="fade-down"
              data-aos-offset="-180"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <Link to="/about/experience">
                <h1>
                  0<span>2</span>
                </h1>
                <h2>
                  E<span>ducation</span>
                </h2>
                <br />
                <p>
                  <span>6 month of experience.</span>
                  <br />In the past six months, I have made tremendous progress in my coding journey. I transitioned from a marketing background to a coder, and it has been an incredibly fulfilling experience. I started with the basics and worked my way up to creating complex projects, utilizing my marketing experience to make sure they not only functioned well but also looked visually appealing. I have also been able to work on various projects with teams, using my marketing skills to communicate ideas and collaborate effectively. My marketing experience has also come in handy in understanding the user experience, making sure that the projects I create not only work well but also provide a great user experience. I am confident that my diverse background will continue to help me excel in my coding career.
                </p>
              </Link>
            </div>
            <div
              className="about__details"
              data-aos="fade-down"
              data-aos-offset="50"
              data-aos-delay="500"
              data-aos-once="true"
            >
              <Link to="/about/internships">
                <h1>
                  0<span>3</span>
                </h1>
                <h2>
                  E<span>xperience</span>
                </h2>
                <p>
                  <span></span> <br />
                </p>
                <ul>
                  <li>
                    <span>General Assembly</span> - 12 Week immersive program where I was challenged to learn an extensive amount of content in a short amount of time. We covered and became familiar with a vast array of programming languages, frameworks, and technologies. I was then given the opportunity to apply all of this new knowledge with in class exercises and building applications individually or within a group environment.{" "}
                  </li>
                </ul>
                <br />
                <p>
                  <span></span> <br />
                </p>
                <ul>
                  <li>
                    <span>Creeden & Associates</span> - As a fellow at Creeden, I had the opportunity to work with a team of experienced professionals and learn about the latest trends and techniques in the industry. On a typical day, I attend meetings with clients and participate in discussions to understand their marketing needs. I also work on various projects, such as conducting market research, creating presentations, and analyzing data to develop strategies for our clients.

                    In addition, I attend workshops and training sessions to expand my knowledge and skills in marketing. I have learned about various marketing channels, such as social media, email, and search engine optimization, and how to effectively use them to reach target audiences. I also have the opportunity to work on real-life projects, which allows me to apply what I have learned in a practical setting.{" "}
                  </li>
                </ul>
              </Link>
            </div>
            <div
              className="about__details"
              data-aos="fade-down"
              data-aos-offset="-180"
              data-aos-delay="700"
              data-aos-once="true"
            >
              <Link to="/about/graduation">
                <h1>
                  0<span>4</span>
                </h1>
                <h2>
                  H<span>obbies</span>
                </h2>
                <br />
                <p>
                  <span>Gamer | Skateboarder | Snowboarder </span>
                  <br />
                  In addition to my passion for web development and design, I also have a love for gaming and extreme sports. When I'm not working on websites, you can often find me immersed in the latest video games or building my own gaming PC. I enjoy the challenge of optimizing my setup for the best performance and enjoy trying out new games and hardware.
                  <br />
                  <br />
                  I am also an avid skateboarder and snowboarder. There's something freeing about being out on a board, whether it's cruising down the street on my skateboard or carving down a mountain on my snowboard. I love the thrill of it and the feeling of being one with my board. These activities allow me to clear my mind and just enjoy the moment.
                  <br />
                  <br />
                  In conclusion, I am a well-rounded individual with a passion for technology, design, gaming, and extreme sports. I am always looking for new and exciting opportunities to combine my interests and continue growing both professionally and personally.
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;

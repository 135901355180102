export const config = {
  portfolio: {
    portfolioLogo: 'Portfolio',
    projectCard: {
      cards: [
        {
          heading: 'Project',
          description:
            '',
          animationDelay: '300',
          buttonLink: '',
          buttonText: 'Deployed Site',
        },
        {
          heading: 'Project',
          description:
            '',
          animationDelay: '500',
          buttonLink: '',
          buttonText: 'Deployed Site',
        },
        {
          heading: 'Project',
          description: '',
          animationDelay: '700',
          buttonLink: '',
          buttonText: 'Deployed Site',
        }
      ],
    },
    internships: {
      internship: [
        {
          companyName: 'General Assembly',
          role: 'Software Engineer Fellow',
          introText: '',
          duration: '10/31/2022 - 02/06/2023',
          keyPointOne: 'Fundamentals of web development: I learned the basics of web development, including HTML, CSS, and JavaScript.',
          keyPointTwo:
            'Front-end frameworks: I learned about popular front-end frameworks such as React and Angular, and how to use them to build interactive and dynamic web applications.',
          keyPointThree: 'Back-end development: I learned how to build the server-side of a web application, including database management and server-side scripting.',
          keyPointFour:
            'Full-stack development: I learned how to connect the front-end and back-end of a web application, and how to deploy and manage a full-stack application in a production environment.',
          offsetValue: '0',
        },
        {
          companyName: 'Creeden & Associates',
          role: 'Marketing and Sales intern',
          introText: '',
          duration: '06/01/2022 - 08/20/2022',
          keyPointOne: 'Gaining hands-on experience: I had the opportunity to work on real projects and gain practical experience in the field.',
          keyPointTwo: 'Understanding the sales and marketing process: I learned about the steps involved in generating leads, closing deals, and promoting products or services.',
          keyPointThree:
            'Collaborating with team members: I had the opportunity to work with marketing and sales professionals and gain insights into their roles and responsibilities.',
          keyPointFour:
            'Supporting ongoing campaigns: I was involved in planning and executing marketing and sales campaigns, as well as analyzing their effectiveness. This provides an opportunity to learn about the various strategies and tactics used in the field.',
          offsetValue: '-1000',
        }
      ],
    },
  },
};

export default config;
